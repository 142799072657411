<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>单笔支付</span>
      </div>
    </div>
    <div class="daik_box">
      <div class="inxix">
        <van-field v-model="userFromData.money" label="代扣金额：" />
        <van-field
          v-model="userFromData.time"
          @click="ckTime"
          label="扣款时间："
        />
        <van-field v-model="userFromData.remark" label="备注：" />
        <van-button class="btn" type="primary" size="large" @click="affirmSign"
          >下一步</van-button
        >
      </div>
      <p></p>
      <van-datetime-picker
        v-model="currentDate"
        v-if="showTime"
        class="ingudn"
        type="date"
        title="选择完整时间"
        @confirm="qdTime"
        @cancel="qxTime"
        :min-date="minDate"
      />
      <!-- 遮罩层 -->
      <div class="wefx" v-if="maskLayer"></div>
      <!-- 结束 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userFromData: {
        money: "",
        time: "",
        remark: "",
        deductionType: 1,
        userId: "xx1",
      },
      minDate: new Date(),
      currentDate: new Date(),
      showTime: false,
      maskLayer: false,
    };
  },
  created() {
    console.log(111);
    // (this.$router.query.id
  },
  mounted() {
    // localStorage.setItem("money",this.userFromData.money)
    this.userId = this.$route.query.userId || "";
    console.log(this.userId);
    if (this.userId) {
      const linuserData = localStorage.getItem("userFromData");
      const pushc = JSON.parse(linuserData);
      this.userFromData = pushc;
      console.log(this.userFromData);
    } else {
      this.hint(); //第一次进入
    }
    // this.channelId = this.$route.query.channelId || '';
  },
  methods: {
    // 提示服务协议
    hint() {
      this.$dialog
        .alert({
          title: "温馨提示",
          message:
            "信贷员和客户不得利用本代扣服务从事侵害他人合法权益之行为，否则本平台有权拒绝提供服务并拒 绝信贷员提现申请，如因此导致本平台及相关方遭 受损失的，应依法承担相应的法律责任。相关行为 包括不限于：1.冒用他人账户使用本服务；1.非法使用他人银行卡账户或无效银行卡账户；3.违法《 银行卡业务员管理办法》使用银行卡或利用银行卡套取现金；4.未经客户本人授权及有诱导客户行为； 5.从事任何可能含有电脑病毒或可能侵害本协议服务系统或资料之行为。",
          beforeClose,
          showConfirmButton: true, //确认按钮
        })
        .catch(() => {});
      function beforeClose(action, done) {
        if (action === "confirm") {
          // 确认按钮
          done();
        }
      }
    },

    // 扣款时间
    ckTime() {
      this.showTime = true;
      this.maskLayer = true;
    },
    // 确定选择时间
    qdTime(value) {
      console.log(value);
      var d = new Date(value);
      var youWant =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      this.userFromData.time = youWant;
      console.log(youWant);
      this.showTime = false;
      this.maskLayer = false;
    },
    // 取消选择时间
    qxTime() {
      this.showTime = false;
      this.maskLayer = false;
    },

    // 确认签署
    affirmSign() {
      if (this.userFromData.money != "" && this.userFromData.time != "") {
        if (this.userFromData.money < 10) {
          this.$notify({ type: "warning", message: "金额不能低于最小金额10" });
          return;
        }
        const linDat = JSON.stringify(this.userFromData);
        localStorage.setItem("userFromData", linDat);
        // 发送请求
        this.$router.push({
          path: "/withholdbincj",
          query: { userMessage: this.userFromData, type: "1" },
        });
      } else {
        this.$notify({ type: "warning", message: "请输入完整的用户信息" });
      }
    },
    goback() {
      // this.$router.go(-1); //返回上一层

      this.$router.push("/xieyi");
    },
  },
};
</script>

<style lang="scss" scoped>
.daik_box {
  margin-top: 2rem;
  .inxix {
    .insasxz {
      position: relative;
    }
    .zhoiuqi {
      position: absolute;
      top: -2%;
      left: 79%;
      height: 1rem;
      color: #19b5fe;
      padding-right: 1rem;
      .van-dropdown-menu {
        z-index: 0;
      }
      /deep/ .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
    .inrs {
      position: relative;
      .fgtrca {
        position: absolute;
        top: -2%;
        left: 22%;
        width: 60%;
        height: 1rem;
        color: #19b5fe;
        padding-right: 1rem;
        .van-dropdown-menu {
          z-index: 0;
        }
        /deep/ .van-dropdown-menu__bar {
          box-shadow: none;
        }
      }
    }
  }
  .btn {
    position: fixed;
    bottom: 0;
  }
}
.wefx {
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
  height: 100%;
  filter: alpha(opacity=100); /*设置透明度为100%*/
  opacity: 1; /*非IE浏览器下设置透明度为100%*/
  display: block;
  z-index: 999;
}
.ingudn {
  position: fixed;
  width: 100%;
  height: 21rem;
  bottom: 0;
  z-index: 9999;
}
</style>
